import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { localStorage } from "@web-monorepo/safe-browser-storage";

// Use this hook to read and write to localStorage in a way that reacts across components.

function useLocalStorageQuery<T>(key: string, initialValue: T) {
  return useQuery<T>(
    ["localStorage", key],
    () => {
      const item = localStorage.getItem(key);
      const itemValue: T = item != null || item != void 0 ? (JSON.parse(item) as T) : initialValue;
      return itemValue;
    },
    {
      initialData: initialValue,
    },
  );
}

function useLocalStorageMutation<T>(key: string) {
  const queryClient = useQueryClient();

  return useMutation<T, unknown, T>({
    mutationFn: async (value: T) => {
      localStorage.setItem(key, JSON.stringify(value));
      await queryClient.invalidateQueries(["localStorage", key]);
      return value;
    },
    mutationKey: ["localStorage", key],
  });
}

export function useReactiveLocalStorage<T>(key: string, initialValue: T) {
  const query = useLocalStorageQuery<T>(key, initialValue);
  const mutation = useLocalStorageMutation<T>(key);

  return [query.data, mutation.mutateAsync] as const;
}
