import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import type { ActionCreatorsMapObject } from "redux";

export { useSelector, useDispatch };

export function useMapDispatch<ActionObject, MapOfActionCreators extends ActionCreatorsMapObject<ActionObject>>(
  actionCreators: MapOfActionCreators,
) {
  const dispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators(actionCreators, dispatch);
  }, [dispatch]); // eslint-disable-line react-hooks/exhaustive-deps
}
